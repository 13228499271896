module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!enTitle) var desc = '';
;
__p += '\r\n<section class="ny-banner">\r\n  <div class="img bg-cover" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="banner-t container">\r\n    <h2><span class="wow fadeInUpBig" data-wow-delay=".5s">' +
((__t = ( enTitle )) == null ? '' : __t) +
'</span></h2>\r\n    <h1><span class="wow fadeInUpBig" data-wow-delay=".3s">' +
((__t = ( title )) == null ? '' : __t) +
'</span></h1>\r\n  </div>\r\n</section>';

}
return __p
}