
import {WOW} from '../../../node_modules/wowjs/dist/wow';
new WOW().init();

$(document).ready(function(){
  if(window.location.href.indexOf('#md') != -1){
    if($('.ny-banner').length > 0){
      let h = $('.ny-banner').outerHeight()
      $(document).scrollTop(h)
    }
  }
})
